import React, { useEffect } from 'react';
import { observer } from 'mobx-react'
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Options from '../utils/Options';
import AppBar from '../components/AppBar';
import Select from '../components/Select';
import Label from '../components/Label';
import ContactUsCollapse from '../components/ContactUsCollapse';
import { useStore as useServiceStore } from "../stores/serviceStore";
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import TuneIcon from '@mui/icons-material/Tune';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Avatar from '@mui/material/Avatar';


import PhoneIcon from '@mui/icons-material/Phone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import EmailIcon from '@mui/icons-material/Email';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import Button from '@mui/material/Button';

import FilterPage from './FilterPage'

import List from '../components/List';

import ContactInfo from '../utils/ContactInfo';

import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import LanguageIcon from '@mui/icons-material/Language';
import WebIcon from '@mui/icons-material/Web';
import Link from '@mui/material/Link';


import SpecialServices from '../components/SpecialServices';
import ResultTypes from '../components/ResultTypes';
import PaymentTypes from '../components/PaymentTypes'
import Appointments from '../components/Appointments'
import Exams from '../components/Exams';
import AdminNotes from '../components/AdminNotes'
import Phones from '../components/Phones';
import WhatsApp from '../components/WhatsApp';
import IconLink from '../components/IconLink';
import Schedule from '../components/Schedule';
import SaleDetails from '../components/SaleDetails';

import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import ShareIcon from '@mui/icons-material/Share';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import ReactGA from "react-ga4";


const useStyles = makeStyles((theme) => ({
  box: {
    width: '100vw',
    flexGrow: 1,
    flexDirection: 'row',
    // backgroundColor: 'pink',
    justifyContent: 'space-around',
  },
  test: {
    // backgroundColor: 'pink'
  },
  drawer: {
    width: 100,
  },
  mainLogo: {
    width: '100vw',
    height: '100vw',
  },
  cornerLogo: {
    width: '40vw',
    hright: '40vw',
  },
  section1: {
    height: '100vh',
    width: '100vw',
    // backgroundColor: 'pink'
  },
  section2: {
    height: '100vh',
    backgroundColor: '#e85454'
  },
  textBox: {
    width: '60%',
  },
  paper: {
    borderColor: 'green'
  },
  manrope: {
    fontFamily: 'manrope',
    fontWeight: 'bold',
  },
  box: {
    maxWidth: '600px',
  },
  beige: {
    borderColor: '#132C5E'
  },
}));

const ServiceDetailPage = observer(() => {

	const classes = useStyles();
  const history = useNavigate();
  const serviceStore = useServiceStore();
  const containerRef = React.useRef(null);
  const params = useParams();
  let navigate = useNavigate();

  const [show, setShow] = React.useState(true);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 350,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShow = () => {
    setShow((prev) => !prev);
  };

  const handleShare = () => {

  }

  useEffect(() => {

    async function get() {
      document.title = 'Detalles'
      serviceStore.clear();
      await serviceStore.getOne(params.id);
      await serviceStore.getDistinctExams();
      ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: serviceStore.service.branch.name });
    }

    get();
  }, []);

	return (
    <Box m={2} mt={10}>
    <Grid
        sx={{
          bgcolor: 'secondary.main',
          pt: 3,
        }}
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
          <AppBar navigateOnClick={true}/>
      </Grid>
    <Slide direction="left" in={show} mountOnEnter>
      <Grid 
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.container}
      > 

      <Grid item>
        <Box m={1}>
          <Grid container justifyContent='flex-start' alignItems='center'>
            <Grid item>
              <Button variant='filled' startIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
                Volver a resultados
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box m={1}>
          <Grid container justifyContent='center' alignItems='center'>
            <Grid item>
              <Typography variant='h5' className={classes.manrope}>
                {serviceStore.service.branch.name}
              </Typography >
            </Grid>
          </Grid>
        </Box>

        <Grid container justifyContent='center' alignItems='center'>
          <Grid item>
            <Box m={1}>
              <Link href={`http://maps.google.com/?q=${serviceStore.service.branch.name}`} variant='caption' color='link'> 
                {`${serviceStore.service.address.address}, ${serviceStore.service.address.neighbourhood}, ${serviceStore.service.address.city}, ${serviceStore.service.address.state}`} 
              </Link>
            </Box>
          </ Grid>
        </Grid>

        {/*Datos de contacto*/}
        <Grid container justifyContent='center' alignItems='center'>
          <Box className={classes.box}>
            <Card>

              {/*<CardHeader title="Datos de contacto" />*/}

              <CardContent>
                  <Grid item>
                    <Box m={1} >
                      <Typography variant="h6">
                        Datos de contacto
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid container direction='row' justifyContent='flex-start' className={classes.test}>
                      {
                        serviceStore.service.contact.phones ? 
                          ( 
                            <Grid item xs={12}>
                              <Phones title='Teléfonos' phones={serviceStore.service.contact.phones} icon={<PhoneIcon />} />
                            </Grid>
                          ) : <div></div>
                      }

                    {/*Celulares*/}
                    
                      {
                        serviceStore.service.contact.cellPhones ? 
                          ( 
                            <Grid item xs={12}>
                              <Phones title='Celulares' phones={serviceStore.service.contact.cellPhones} icon={<PhoneAndroidIcon />} />
                            </Grid>
                          ) : <div></div>
                      }

                    {/*WhatsApp*/}
                    <Grid item xs={12}>
                      {
                        serviceStore.service.contact.whatsapp ? 
                          ( 
                            <WhatsApp phones={serviceStore.service.contact.whatsapp} />
                          ) : <div></div>
                      }
                    </Grid>

                    {/*Correo */}
                    <Grid item xs={12}>
                      {
                        serviceStore.service.contact.email ? 
                          ( 
                            <IconLink icon={<EmailIcon />} value={serviceStore.service.contact.email} href={`mailto:${serviceStore.service.contact.email}`} />
                          ) : <div></div>
                      }
                    </Grid>

                    {/*Pagina web */}
                    <Grid item>
                      {
                        serviceStore.service.contact.website ? 
                          ( 
                            <IconLink icon={<WebIcon />} value={serviceStore.service.contact.website} href={serviceStore.service.contact.website} />
                          ) : <div></div>
                      }
                    </Grid>

                  </Grid>
              </CardContent>

              <CardActions disableSpacing>

              {
                serviceStore.service.contact.instagram || serviceStore.service.contact.facebook || serviceStore.service.contact.twitter ?
                (
                  <Box m={1} >
                    <Typography>
                      Ir a Redes Sociales:
                    </Typography>
                  </Box>
                ) : <div></div>
              }
                  {/*<Grid item>*/}
                  {/*</Grid>*/}
              {
                serviceStore.service.contact.instagram ?
                (
                  <IconButton aria-label="Instagram" color="secondary" onClick={()=> window.open(serviceStore.service.contact.instagram, "_blank")}>
                    <InstagramIcon />
                  </IconButton>
                ) : <div></div>
              }

              {
                serviceStore.service.contact.facebook ?
                (
                  <IconButton aria-label="Facebook" color="primary" onClick={()=> window.open(serviceStore.service.contact.facebook, "_blank")}>
                    <FacebookIcon />
                  </IconButton>
                ) : <div></div>
              }

              {
                serviceStore.service.contact.twitter ?
                (
                  <IconButton aria-label="Twitter" color="primary" onClick={()=> window.open(serviceStore.service.contact.twitter, "_blank")}>
                    <TwitterIcon />
                  </IconButton>
                ) : <div></div>
              }

              </CardActions>
            </Card>
          </Box>
        </Grid>

      {/*Promocion*/}
        {
          serviceStore.service.plans?.sale ? 
            (
              <>
                <SaleDetails 
                  saleNotes={serviceStore.service.plans?.saleNotes} 
                  saleLink={serviceStore.service.plans?.saleLink}
                />
              </>
            ) : <></>
        }

        {/*Metodos de atencion*/}
        {
          serviceStore.service.appointments ? 
            (
              <>
                <Appointments appointments={serviceStore.service.appointments}/>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider variant="middle" />
                  </Grid>
                </Grid>
              </>
            ) : <div></div>
        }

        {/*Servicios especiales*/}
        {
          serviceStore.service.specialServices && serviceStore.service.specialServices.length !== 0 ? 
            (
              <>
                <SpecialServices specialServices={serviceStore.service.specialServices}/>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider variant="middle" />
                  </Grid>
                </Grid>
              </>
            ) : <></>
        }

        {
          serviceStore.service.paymentTypes ? 
            (
                <PaymentTypes paymentTypes={serviceStore.service.paymentTypes}/>
            ) : <div></div>
        }

        <Grid container>
          <Grid item xs={12}>
            <Divider variant="middle" />
          </Grid>
        </Grid>

        {/*Metodos de entrega de resultados*/}
        {
          serviceStore.service.resultTypes ? 
            (
              <>
                <ResultTypes resultTypes={serviceStore.service.resultTypes}/>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider variant="middle" />
                  </Grid>
                </Grid>
              </>
            ) : <></>
        }

        <Grid container>
          <Grid item xs={12}>
            <Divider variant="middle" />
          </Grid>
        </Grid>
        
        {
          serviceStore.service.notes.admin ? 
            (
              <Grid container justifyContent='center' alignItems='center'>
                <AdminNotes note={serviceStore.service.notes.admin}/>
              </Grid>
            ) : <></>
        }

        {
          serviceStore.service.exams ? 
            (
              <Grid container justifyContent='center' alignItems='center'>
                <Exams exams={serviceStore.service.exams}/>
              </Grid>
            ) : <></>
        }

        {/*Contactanos*/}
        <Box pt={3}>
          <ContactUsCollapse 
            title='¿Encontraste algun error en esta información?' 
            description={`Por favor repórtalo a través de nuestras redes para ayudarnos a mejorar el servicio que prestamos`}
            icon={<InfoIcon color='dark' />}
          />
        </Box>

        <Grid item>
          <Zoom in={!trigger}>
            <Box
              role="presentation"
              sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
             <Fab size="small" aria-label="scroll back to top" color='primary'>
                <ShareIcon onClick={handleClick}/>
              </Fab>
            </Box>
          </Zoom>
        </Grid>


      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={()=> window.open(`https://wa.me/?text=${window.location.href}`, "_blank")}>
          <IconButton aria-label="WhatsApp" color="primary">
            <WhatsAppIcon />
          </IconButton>
          WhatsApp
        </MenuItem>
{/*
        <MenuItem onClick={()=> window.open(`http://https://wa.me/?text=${window.location.href}`, "_blank")} >
          <IconButton aria-label="Instagram" color="primary">
            <InstagramIcon />
          </IconButton>
          Instagram
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <IconButton aria-label="Facebook" color="primary">
            <FacebookIcon />
          </IconButton>
          Facebook
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <IconButton aria-label="Copy" color="primary">
            <ContentCopyIcon />
          </IconButton>
          Copiar enlace
        </MenuItem>*/}

      </Menu>



        </Grid>
      </Grid>
    </Slide>
    </Box>
	);
});

export default ServiceDetailPage;