import React from 'react'
import { observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const Exams = observer(({exams}) => {
  return(
      <Grid container>
        <Grid item>
          <Box m={1} >
            <Typography variant="h6">
              Exámenes
            </Typography>
          </Box>
        </Grid>

          <Grid item xs={12}>
            <Box m={2}>
              <Grid container spacing={1}>
    				      { exams.map(function(exam) {
    				        return (
                      <Grid item>
                        <Chip 
                          label={exam.name} 
                          variant='outlined'
                          color='secondary'
                          // sx={{ borderColor: '#132c5e', color: '#132c5e'}}
                          />
                      </Grid>
    				        )
    				      	})}
              </Grid>
            </Box>
          </Grid>
        </Grid>
  );
});

export default Exams;