import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Routes from './Routes';
import ServiceStore, { ServiceStoreProvider } from './stores/serviceStore';
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-PX6XPN64'
}
 
TagManager.initialize(tagManagerArgs)

ReactGA.initialize('G-89J10B0CFH');

const theme = createTheme({
  palette: {
    primary: {
      main: '#8bc8ea', // light blue
      contrastText: '#132c5e', // dark blue
      cream: '#FAFACB'
    },
    secondary: {
      main: '#e85454', // red
    },
    link: '#132c5e',
    dark: {
      main: '#132C5E',
    }
  },
  typography: {
    fontFamily: 'manrope',
  },
});

const useStyles = makeStyles((theme) => ({
  box: {
    // maxWidth: 500,
    // backgroundColor: 'orange'
  },
}));

function usePageViews() { // Google Analytics Page Views
  let location = useLocation();
  React.useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: location.pathname, title: document.title });
  }, [location]);
}

const serviceStore = new ServiceStore();

function App() {
  usePageViews();
  const classes = useStyles();

  return (
      <div>
          <ThemeProvider theme={theme}>
          <ServiceStoreProvider store={serviceStore}>
            <Routes/>
          </ServiceStoreProvider>
          </ThemeProvider>
      </div>
  );
}

export default () => (
  <Router>
    <App />
  </Router>
);
