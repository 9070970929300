const ContactInfo = {
	tuExamenMedico: {
		email: 'tuexamenmedico.atc@gmail.com',
		whatsapp: '5154617484',
		instagram: 'https://www.instagram.com/tuexamenmedico',
		facebook: 'https://www.facebook.com/tuexamenmedico',
		twitter: 'https://twitter.com/tuexamenmedico',
	},
	dataUtil: {

	}
};

export default ContactInfo;