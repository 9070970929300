import React from 'react'
import Select from 'react-select'
import { observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';

import SendIcon from '@mui/icons-material/Send';
import DevicesIcon from '@mui/icons-material/Devices';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import GroupIcon from '@mui/icons-material/Group';

import HomeIcon from '@mui/icons-material/Home';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import IconButton from '@mui/material/IconButton';

import Divider from '@mui/material/Divider';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles((theme) => ({
  manrope: {
		fontFamily: 'Manrope',
  },
}));

const appointmentsMap = {
  'Atención móvil a domicilio': {
  	icon: (<HomeIcon color='primary'/>),
  },
  'Orden de llegada sin cita': {
  	icon: (<DirectionsWalkIcon color='primary'/>),
  },
  'Previa cita por hora': {
  	icon: (<AccessTimeIcon color='primary'/>),
  },
  'Previa cita por orden de llegada': {
  	icon: (<CalendarTodayIcon color='primary'/>),
  },
}

const Appointments = observer(({appointments}) => {

	const classes = useStyles();

  return(
			<Grid container>
        <Grid item>
          <Box m={1} pt={1} >
            <Typography variant="h6">
              Métodos de atención
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
            <List dense={true}>
				      { appointments.map(function(appointment) {
				        return (
		                <ListItem>
		                  <ListItemIcon>
		                    {appointmentsMap[appointment].icon}
		                  </ListItemIcon>
		                  <ListItemText
		                    primary={appointment}
		                    primaryTypographyProps={{variant: 'body1'}}
		                    className={classes.manrope}
		                  />
		                </ListItem>
				        )
				      	})}
            </List>
        </Grid>
      </Grid>
  );
});

export default Appointments;