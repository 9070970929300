import React, { useEffect } from 'react';
import { observer } from 'mobx-react'
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Options from '../utils/Options';
import Select from '../components/Select';
import { useStore as useServiceStore } from "../stores/serviceStore";
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import ReactGA from "react-ga4";
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';


import Fade from '@mui/material/Fade';
import TuneIcon from '@mui/icons-material/Tune';

import List from '../components/List';

const useStyles = makeStyles((theme) => ({
  filters: {
    // width: '100vw',
  },
  title: {
    fontFamily: 'manrope'
  },
  manrope: {
    fontFamily: 'manrope',
    fontWeight: 'bold',
  }
}));

const FilterPage = observer(() => {

	const classes = useStyles();
  const history = useNavigate();
  const serviceStore = useServiceStore();
  const containerRef = React.useRef(null);

  const [show, setShow] = React.useState(true);
  const [drawerOpen, setDrawerOpen] = React.useState(true);

const toggleDrawer = (open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    return;
  }
  setDrawerOpen(open);
};

  const handleShow = () => {
    setShow((prev) => !prev);
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: 'Filtros' });
  }, []);

	return (
    <Box m={2}>
    <Grid 
      container 
      direction="column"
      alignItems="flex-start"
      justifyContent="space-between"
      className={classes.filters}
      spacing={0}
    >
        <Grid item>
          <Box p={1}>
            <Typography variant="h6" className={classes.manrope}>
              Dirección
            </Typography>
          </Box>
        </Grid>

      <Grid container >
        <Grid item>
            <Select
              name={'municipality'} 
              label='Municipio'
              options={serviceStore.filterOptions.municipality}
              defaultValue={serviceStore.filters.address.municipality || ''}
              action={(municipality) => {serviceStore.setMunicipality(municipality)}}
            />
        </Grid>

        <Slide direction="left" in={serviceStore.filters.address.municipality != null}>
          <Grid item>
            <IconButton color='secondary'>
              <ClearIcon onClick={() => {serviceStore.setMunicipality(null)}}/>
            </IconButton>
          </Grid>
        </Slide>
      </Grid>

      <Grid container >
        <Grid item>
            <Select
              name={'city'} 
              label='Ciudad'
              options={serviceStore.filterOptions.city}
              defaultValue={serviceStore.filters.address.city || ''}
              action={(city) => {serviceStore.setCity(city)}}
            />
        </Grid>

        <Slide direction="left" in={serviceStore.filters.address.city != null}>
          <Grid item>
            <IconButton color='secondary'>
              <ClearIcon onClick={() => {serviceStore.setCity(null)}}/>
            </IconButton>
          </Grid>
        </Slide>
      </Grid >

      <Grid container >
        <Grid item>
            <Select
              name={'state'} 
              label='Estado'
              options={serviceStore.filterOptions.state}
              defaultValue={serviceStore.filters.address.state || ''}
              action={(state) => {serviceStore.setState(state)}}
            />
        </Grid>

          <Grid item>
            <Slide direction="left" in={serviceStore.filters.address.state != null}>
            <IconButton color='secondary'>
              <ClearIcon onClick={() => {serviceStore.setState(null)}}/>
            </IconButton>
            </Slide>            
          </Grid>
      </Grid >

      <Grid container >
        <Grid item>
            <Select
              name={'neighbourhood'} 
              label='Zona'
              options={serviceStore.filterOptions.neighbourhood}
              defaultValue={serviceStore.filters.address.neighbourhood || ''}
              action={(neighbourhood) => {serviceStore.setNeighbourhood(neighbourhood)}}
            />
        </Grid>

          <Grid item>
            <Slide direction="left" in={serviceStore.filters.address.neighbourhood != null}>
            <IconButton color='secondary'>
              <ClearIcon onClick={() => {serviceStore.setNeighbourhood(null)}}/>
            </IconButton>
            </Slide>            
          </Grid>
      </Grid >


      <Divider style={{width:'100%'}} />


      <Grid item>
        <Box p={1}>
          <Typography variant="h6" className={classes.manrope}>
            Atención al paciente
          </Typography>
        </Box>
      </Grid>

      <Grid container>
        <Grid item>
            <Select
              name={'appointments'} 
              label='Metodo de atención'
              options={serviceStore.filterOptions.appointments}
              defaultValue={serviceStore.filters.appointments || ''}
              action={(appointments) => {serviceStore.setAppointments(appointments)}}
            />
        </Grid>
        <Grid item>
          <Slide direction="left" in={serviceStore.filters.appointments != null}>
          <IconButton color='secondary'>
            <ClearIcon onClick={() => {serviceStore.setAppointments(null)}}/>
          </IconButton>
          </Slide>            
        </Grid>
      </Grid >

      <Grid container>
        <Grid item>
            <Select
              name={'state'} 
              label='Servicios Especiales'
              options={serviceStore.filterOptions.specialServices}
              defaultValue={serviceStore.filters.specialServices || ''}
              action={(specialServices) => {serviceStore.setSpecialServices(specialServices)}}
            />
        </Grid>
        <Grid item>
          <Slide direction="left" in={serviceStore.filters.specialServices != null}>
          <IconButton color='secondary'>
            <ClearIcon onClick={() => {serviceStore.setSpecialServices(null)}}/>
          </IconButton>
          </Slide>            
        </Grid>
      </Grid >

      <Grid container>
        <Grid item>
            <Select
              name={'resultTypes'}
              label='Entrega de resultados'
              options={serviceStore.filterOptions.resultTypes}
              defaultValue={serviceStore.filters.resultTypes || ''}
              action={(resultTypes) => {serviceStore.setResultTypes(resultTypes)}}
            />
        </Grid>
        <Grid item>
          <Slide direction="left" in={serviceStore.filters.resultTypes != null}>
          <IconButton color='secondary'>
            <ClearIcon onClick={() => {serviceStore.setResultTypes(null)}}/>
          </IconButton>
          </Slide>            
        </Grid>
      </Grid >

      <Divider style={{width:'100%'}} />

      <Grid item>
        <Box p={1}>
          <Typography variant="h6" className={classes.manrope}>
            Otros
          </Typography>
        </Box>
      </Grid>

      <Grid container>
        <Grid item>
            <Select
              name={'paymentTypes'} 
              label='Formas de pago'
              options={serviceStore.filterOptions.paymentTypes}
              defaultValue={serviceStore.filters.paymentTypes || ''}
              action={(paymentTypes) => {serviceStore.setPaymentTypes(paymentTypes)}}
            />
        </Grid>
        <Grid item>
          <Slide direction="left" in={serviceStore.filters.paymentTypes != null}>
          <IconButton color='secondary'>
            <ClearIcon onClick={() => {serviceStore.setPaymentTypes(null)}}/>
          </IconButton>
          </Slide>            
        </Grid>
      </Grid >
    </Grid>
    </Box>
	);
});

export default FilterPage;