import React from 'react'
import Select, { createFilter } from 'react-select';
import { observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
  searchField: {
    // width: '100%',
    // backgroundColor: 'blue'
  },
}));

const TXMSearchField = observer(({name, options, defaultValue, action, label}) => {

	const classes = useStyles();

  const filterConfig = {
    // ignoreCase: true,
    // ignoreAccents: true,
    // matchFrom: 'start'
  };

	const customStyles = {
	  control: base => ({
	    ...base,
	    width: '100%',
	  })
	};

	// formatting options
	let selectOptions = [];
	for (const option of options) {
		selectOptions.push({
			value: option,
			label: option,
		});
	}

	let value = {value: defaultValue, label: defaultValue};

	function handleChange(selectedOption) {
		ReactGA.event('search', { search_term: selectedOption.value });
		action(selectedOption.value);
	}

  return(
		<div className={classes.searchField}>
	  	<Select
				// size="large"
				name={name}
				options={selectOptions}
				value={value}
				onChange={handleChange}
				classNamePrefix="select"
				styles={customStyles}
				filterOption={createFilter(filterConfig)}
	  	/>
		</div>
  );
});

export default TXMSearchField;