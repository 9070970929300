import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react'
import ContactInfo from '../utils/ContactInfo';
import Grid from '@material-ui/core/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InfoIcon from '@mui/icons-material/Info';
import EmailIcon from '@mui/icons-material/Email';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Button from '@mui/material/Button';


const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    maxWidth: '600px'
  },
  blue: {
    backgroundColor: '#8bc8ea'
  }
}));

const ContactUsCollapse = observer(({icon, title, description}) => {

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return(
    <Grid container justifyContent='center' alignItems='center'>
      <Box  className={classes.card}>
        <Card >
          <CardHeader
            onClick={handleExpandClick}
            avatar={
              icon
            }
            action={
              <IconButton aria-label="settings" onClick={handleExpandClick}>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            }
            title={title}
            className={classes.blue}
          />
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography variant="body2">
                {description}
              </Typography>
            </CardContent>

            <CardActions>

            <Link href={`https://wa.me/+1${ContactInfo.tuExamenMedico.whatsapp}`} variant='body2' color='link'>
              <IconButton aria-label="WhatsApp" color="primary">
                <WhatsAppIcon />
              </IconButton>
            </Link>

              <IconButton aria-label="Instagram" color="secondary" onClick={()=> window.open(ContactInfo.tuExamenMedico.instagram, "_blank")}>
                <InstagramIcon />
              </IconButton>

              <IconButton aria-label="Facebook" color="primary" onClick={()=> window.open(ContactInfo.tuExamenMedico.facebook, "_blank")}>
                <FacebookIcon />
              </IconButton>

              <IconButton aria-label="Twitter" color="primary" onClick={()=> window.open(ContactInfo.tuExamenMedico.twitter, "_blank")}>
                <TwitterIcon />
              </IconButton>

              <IconButton aria-label="Email" color="primary" onClick={()=> window.open(`mailto:${ContactInfo.tuExamenMedico.email}`, "_blank")}>
                <EmailIcon />
              </IconButton>

            </CardActions>
          </Collapse>
        </Card>
      </Box>
    </Grid>
  );
});

export default ContactUsCollapse;