import React, { useEffect } from 'react';
import { observer } from 'mobx-react'
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Options from '../utils/Options';
import Select from '../components/Select';
import SearchField from '../components/SearchField';
import { useStore as useServiceStore } from "../stores/serviceStore";
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ContactInfo from '../utils/ContactInfo';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import Divider from '@mui/material/Divider';

import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ContactUsCollapse from '../components/ContactUsCollapse';

import useScrollTrigger from '@mui/material/useScrollTrigger';
import ReactGA from "react-ga4";


import Button from '@mui/material/Button';

import FilterPage from './FilterPage'

import List from '../components/List';
import TopResultsList from '../components/TopResultsList';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import AppBar from '../components/AppBar';
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 300,
  },
  filterBar: {
    // backgroundColor: 'pink',
    width: '80vw',
    // height: '30px',
  },
  test: {
    maxWidth: '600px'
  },
  searchField: {
    width: '100vw',
    maxWidth: '600px',
    // backgroundColor: 'pink'
  }
}));

const SearchPage = observer(() => {

	const classes = useStyles();
  const history = useNavigate();
  const serviceStore = useServiceStore();
  const containerRef = React.useRef(null);

  let [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = React.useState(true);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [filterSale, setFilterSale] = React.useState(true);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleFilterSale = (event) => {
    setFilterSale(!filterSale);
    serviceStore.setSale(filterSale);
  }

  const handleBackToTop = (event) => {
    const anchor = document.querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 400,
  });

  const handleShow = () => {
    setShow((prev) => !prev);
  };

  const handleSetExam = (exam) => {
    setSearchParams({ q: exam }, {})
    serviceStore.clearFilters();
    serviceStore.setExams(exam);
  }

  useEffect(() => {
    // serviceStore.find();
    document.title = 'Buscar';
    serviceStore.getDistinctExams();

    if (searchParams.get('q')) {
      serviceStore.setExams(searchParams.get('q')); // if search param available in uril, search
    }
  }, []);

	return (
    <Box sx={{ flexGrow: 1 }}>
      <Slide direction="right" in={show} mountOnEnter>
        <Grid
          container 
          direction="column"
          alignItems="center"
          justifyContent="flex-start"
          spacing={0}
        >
          <Grid item xs={12} id="back-to-top-anchor">
            <Box>
              <AppBar navigateOnClick={false}/>
            </Box>
          </Grid>

          {
            serviceStore.filtersLoading ? 
            (
              <div>
                <Box pt={12}>
                  <Skeleton variant="text" animation="wave" width={200} sx={{ bgcolor: '#eff7fc' }}/>
                  <br/>
                  <Skeleton variant="circular" animation="wave" width={30} height={30} sx={{ bgcolor: '#eff7fc' }}/>
                </Box>
              </div>
            )
            :
            (
              <Box pt={8}>
                {
                  serviceStore.filterOptions.sales[0] || serviceStore.filterOptions.sales[1] ?
                  <>
                    <Box m={2}>
                      <FormGroup>
                        <FormControlLabel 
                          control={
                            <Switch
                              color='secondary' 
                              checked={serviceStore.filters.sale}
                              onClick={() => serviceStore.setSale(!serviceStore.filters.sale)}
                            />
                          }
                          label={
                            <Button 
                              variant="contained" 
                              color='secondary'
                              onClick={() => serviceStore.setSale(!serviceStore.filters.sale)}
                            >
                              Ver promos de {serviceStore.serviceSearch.results[0].name}
                            </Button>
                          }
                        />
                      </FormGroup>
                    </Box>
                  </>
                  :
                  <></>
                }
                <Box m={2}>

                  <Button variant="contained" startIcon={<TuneIcon />} onClick={toggleDrawer(true)}>
                    Ver todos los filtros
                  </Button>
                </Box>
                <Divider variant='middle'/>
              </Box>
            )
          }

          {
            serviceStore.loading ? 
              (
                <Box m={2} pt={8}>
                  
                  <Skeleton variant="rectangular" animation='wave' width={210} height={118} sx={{ bgcolor: '#eff7fc' }}/>
                  <br/>
                  <Skeleton variant="rectangular" animation='wave' width={210} height={118} sx={{ bgcolor: '#eff7fc' }}/>
                  <br/>
                  <Skeleton variant="rectangular" animation='wave' width={210} height={118} sx={{ bgcolor: '#eff7fc' }}/>
                  <br/>
                </Box>
              ) 
              : 
              (
              <Slide direction="up" in={serviceStore.serviceSearch.count}>
                <Box>
                <Grid item xs={12}>
                  {
                    serviceStore.serviceSearch.topResults.length ?
                    <>
                      <TopResultsList
                        services={serviceStore.serviceSearch.topResults}
                        exam={serviceStore.filters['exams.name']}
                      />
                      <Divider variant='middle' color='#8bc8ea'/>
                    </>
                    :
                    <></>
                  }
                  <List 
                    services={serviceStore.serviceSearch.results}
                    exam={serviceStore.filters['exams.name']}
                  />
                </Grid>
                </Box>
              </Slide>
              )
          }

          <Box pl={2} pr={2}>
            <ContactUsCollapse
              title='¿No consigues el examen médico o la consulta que necesitas?'
              description={`Comunícate con nosotros a través de nuestras redes para apoyarte. Te contactaremos lo antes posible. ¡Gracias!`}
              icon={<SearchIcon color='dark' />}
            />
          </Box>
          {/*</Slide>*/}

          {/*<Grid item>*/}
            {/*<Box sx={{ flexGrow: 1 }}>*/}
              <Drawer
                anchor={'right'}
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                // className={classes.drawer}
              >
                <FilterPage />
                <Button 
                  variant="contained"
                  onClick={toggleDrawer(false)}
                >
                  {`Mostrar ${serviceStore.serviceSearch.count} resultados`}
                </Button>
              </Drawer>
              {/*</Box>*/}
          {/*</Grid>*/}

          <Grid item>
            <Zoom in={trigger}>
              <Box
                onClick={handleBackToTop}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
              >
               <Fab color="primary" size="small" aria-label="scroll back to top">
                  <KeyboardArrowUpIcon />
                </Fab>
              </Box>
            </Zoom>
          </Grid>
        </Grid>
      </Slide>
    </Box>
	);
});

export default SearchPage;