import React from 'react'
import Select from 'react-select'
import { observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';

import SendIcon from '@mui/icons-material/Send';
import DevicesIcon from '@mui/icons-material/Devices';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import GroupIcon from '@mui/icons-material/Group';

import HomeIcon from '@mui/icons-material/Home';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import FastForwardIcon from '@mui/icons-material/FastForward';
import IconButton from '@mui/material/IconButton';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

import Divider from '@mui/material/Divider';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


const useStyles = makeStyles((theme) => ({
  manrope: {
    fontFamily: 'Manrope',
  },
  saleBox: {
    maxWidth: '600px',
  }
}));

const SaleDetails = observer(({saleNotes, saleLink}) => {

  const classes = useStyles();

  return(
      <Box mt={2} p={1} sx={{ border: 3, borderRadius: 2, borderColor: '#e85454'}} className={classes.saleBox}>
      <Grid container alignItems='center'>
        <Grid item>
          <IconButton>
            <LocalOfferIcon color='secondary' />
          </IconButton>
        </Grid>
        <Grid item>
            <Typography variant="h6">
              Promoción
            </Typography>
        </Grid>
        <Grid item xs={12}>
        <div onClick={() => window.open(saleLink)}>
        <Box p={1}>
          <Typography variant='body' className={classes.manrope}>
            <pre style={{ fontFamily: 'inherit', whiteSpace: 'pre-wrap'}}>
              {saleNotes}
            </pre>
          </Typography>
        </Box>
        </div>
        </Grid>
      </Grid>
      </Box>
  );
});

export default SaleDetails;