import React from 'react'
import { observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid';
import Typography from '@mui/material/Typography';
import PhoneIcon from '@mui/icons-material/Phone';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';


const TXMIconLink = observer(({icon, href, value}) => {
  return(
  <Grid container justifyContent='flex-start' alignItems='center' spacing={1}>
    <Grid item>
      <IconButton >
        {icon}
      </ IconButton>
    </Grid>
    <Grid item>
      <Grid item>
        <Link href={href} variant='body2' color='link'> {value} </Link>
      </ Grid>
  </ Grid>
</Grid>
  );
});

export default TXMIconLink;