import React from 'react'
import Select from 'react-select'
import { observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    // minWidth: 100,
    // maxWidth: 150,
    width: 90,
    marginTop: '12px'
  },
}));

const TXMSelect = observer(({name, options, defaultValue, action, label}) => {

	const classes = useStyles();

	const customStyles = {
	  container: base => ({
	    ...base,
	    minWidth: 150,
	    maxWidth: 150,
	  })
	};

	// formatting options
	let selectOptions = [];
	for (const option of options) {
		selectOptions.push({
			value: option,
			label: option,
		});
	}

	let value = {value: defaultValue, label: defaultValue};

	function handleChange(selectedOption) {
		action(selectedOption.value);
	}

  return(
  	<Grid 
      container 
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      spacing={1}
  	>
			<Grid 
				item 
				className={classes.label}
			>
	      {label}
	  	</Grid>
			<Grid item>
		  	<Select
					// size="large"
					name={name}
					options={selectOptions}
					value={value}
					onChange={handleChange}
					classNamePrefix="select"
					styles={customStyles}
		  	/>
	  	</Grid>
  	</Grid>
  );
});

export default TXMSelect;