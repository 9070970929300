import * as React from 'react';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const steps = [
  {
    label: 'Entra a Tu Examen Médico',
    description: `Haz click en Busca y Encuentra. Escribe o selecciona el nombre del examen o consulta que necesitas.`,
  },
  {
    label: 'Filtra tu búsqueda',
    description:
      `Puedes filtrar tu busqueda por ubicación, método de atención y formas de pago.`,
  },
  {
    label: 'Selecciona el centro de atención o médico de tu preferencia.',
    description: `Consulta los datos detallados. Interactúa directamente con la institución.`,
  },
];

const TXMStepper = observer(() => {
  let navigate = useNavigate();

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    
    // check if final step. If so, navigate
    if(activeStep === steps.length - 1) // final step
      navigate("/buscar")
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical" variant='text'>
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Último paso</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Busca y Encuentra' : 'Siguiente'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Anterior
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
{/*      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>Busca y Encuentra la opción más conveniente para ti</Typography>
          <Button variant='filled' onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Busca y Encuentra
          </Button>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}*/}
    </Box>
  );
})

export default TXMStepper;