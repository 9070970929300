import React from 'react';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import ReactGA from "react-ga4";
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { Typography } from '@mui/material';
import { Link } from '@mui/material';
import { Box } from '@mui/material';
import { Grid } from '@mui/material';
import { Button } from '@mui/material';
import { Stack } from '@mui/material';
import { Fab } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import CircleIcon from '@mui/icons-material/Circle';

import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import Stepper from '../components/Stepper';
import AppBar from '../components/AppBar';

import ContactInfo from '../utils/ContactInfo';


import { useStore as useServiceStore } from "../stores/serviceStore";

const features = [
	'Filtrar por ubicación, método de atención y formas de pago',
	'Encontrar exámenes y consultas en promoción ',
	'Compartir los datos con familiares y amigos',
	'Interactuar directamente con las instituciones y médicos',
	'Solicitar asistencia para búsquedas si se requiere',
	'Ahorrar tiempo al facilitar las búsquedas',
];

const steps = [
	'Haz click en busca y encuentra',
	'Escribe o selecciona el nombre del examen o consulta que necesitas',
	'Filtra tu búsqueda por ubicación, método de atención y formas de pago',
	'Selecciona el centro de atención o médico de tu preferencia',
	'Contacta directamente al centro de atención o al médico',
];

const dataHighlights = [
	{
		title: 'Confiable',
		description: 'Siempre actualizada',
	},
	{
		title: 'Centralizada',
		description: 'Todo en un mismo lugar',
	},
	{
		title: 'Numerosa',
		description: 'Miles de exámenes a consultar. Cientos de centros y médicos a donde acudir',
	},
	{
		title: 'Detallada',
		description: 'Toda la información que necesitas para escoger tu mejor opción',
	},
];


const LandingPage = observer(() => {
	let navigate = useNavigate();
	const serviceStore = useServiceStore();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: 'Tu Examen Medico' });
    serviceStore.getDistinctExams();
  }, []);

	return (
    <Grid
      sx={{
        overflow: 'hidden',
      }}
      container
      direction="column"
      alignItems="center"
    >

    	<Grid
	    	sx={{
	    		bgcolor: 'secondary.main',
	    		p: 3,
	    	}}
    		container
    		direction="row"
    		alignItems="center"
    		justifyContent="space-between"
    	>
	    		<AppBar navigateOnClick={true}/>
    	</Grid>

    	{/* Section 1 */}
    	<Grid
	    	sx={{
	    		bgcolor: 'secondary.main',
	    		p: 6,
	    	}}
    		container
    		direction="row"
    		alignItems="center"
    		justifyContent="space-between"
    		spacing={3}
    	>
    		<Grid item xs={12} sm={6}>	
    			<Typography sx={{ typography: { xs: 'h6', sm: 'h4', l: 'h3' }}} color="#FAFACB" >
    				Busca todos los lugares donde puedes hacer tus exámenes o consultas médicas y escoge la opción más conveniente para ti
    			</Typography>
    			{/*<Box display='inline' sx={{fontWeight: 'bold'}} color='primary.contr
astText'>Busca</Box> de forma fácil y rápida los lugares donde puedes hacer tus exámenes médicos y <
Box display='inline' sx={{fontWeight: 'bold'}} color='primary.contrastText'>encuentra</Box> la opció
n más conveniente para ti.*/}
    			<br/>
    			<br/>
    			<Grid container spacing={3}>
    				<Grid item>
			        <Button
								variant="contained"
								color='primary'
								size='large'
								onClick={() => navigate('/buscar')}
		           >
								Busca y Encuentra
							</Button>
						</Grid>
					</Grid>
    		</Grid>

    		<Grid item xs={12} sm={5} sx={{marginTop: '32px', marginRight: '32px'}}>
    			<Box>
						<img src="/temLogoSlogan.png" width='100%' height='100%'/>
					</Box>
    		</Grid>

    		<Grid item xs={12} sm={5}>
    		</Grid>
    	</Grid>

    	<Grid
	    	sx={{
	    		bgcolor: '#FAFACB',
	    		p: 6,
	    	}}
    		container
    		direction="column"
    		alignItems="center"
    		justifyContent="center"
    		spacing={3}
    	>
    	  <Grid item xs={12} sm={12}>
    			<Typography sx={{ typography: { xs: 'h5', sm: 'h5', l: 'h3' } }} >
    				¿Cómo funciona?
    			</Typography>
    		</Grid>
    		<Grid item xs={12} sm={12}>	
    			<List sx={{ bgcolor: 'main.contrastText' }}>
    				{
    					steps.map((step, index) => {
	    					return (
						      <ListItem>
						      	<ListItemIcon>
				              <Typography
				                sx={{ display: 'inline' }}
				                component="span"
				                variant="h4"
				                color="#132C5E"
				              >
				                {index + 1}
				              </Typography>
				            </ListItemIcon>
						        <ListItemText
						          primary={
						            <React.Fragment>
						              <Typography
						                sx={{ display: 'inline' }}
						                component="span"
						                variant="body"
						                // color="white"
						              >
						                {step}
						              </Typography>
						            </React.Fragment>
						          }
						        />
						      </ListItem>
	    					);
    					})
    				}
    			</List>
    			{/*<Stepper />*/}

    		</Grid>
    		<Grid item xs={12} sm={12}>	
    			<Typography sx={{ typography: { xs: 'h6', sm: 'h5', l: 'h3' } }} >
    				Comunícate con nosotros si necesitas ayuda.
    			</Typography>
    		</Grid>
    		<Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            <IconButton aria-label="Email" color="primary" onClick={()=> window.open(`mailto:${ContactInfo.tuExamenMedico.email}`)}>
              <EmailIcon /> 
            </IconButton>
            <IconButton aria-label="WhatsApp" color="primary" onClick={() => window.open(`https://wa.me/+1${ContactInfo.tuExamenMedico.whatsapp}`)}>
              <WhatsAppIcon />
            </IconButton>
            <IconButton aria-label="Instagram" color="primary" onClick={() => window.open(ContactInfo.tuExamenMedico.instagram)}>
              <InstagramIcon />
            </IconButton>
            <IconButton aria-label="Facebook" color="primary" onClick={() => window.open(ContactInfo.tuExamenMedico.facebook)}>
              <FacebookIcon />
            </IconButton>
          </Stack>    		
    		</Grid>
    		<Grid item xs={12} sm={12}>	
    			<Typography sx={{ typography: { xs: 'body', sm: 'h6', l: 'h3' } }} >
    				Para mas información, te invitamos a ver estos pasos <Link color='primary.contrastText' href='https://www.instagram.com/tv/CaKnTakFMYH/?utm_source=ig_web_copy_link'>en video</Link>
    			</Typography>
    		</Grid>
    	</Grid>

    	{/* Section 2 */}
    	<Grid
	    	sx={{
	    		bgcolor: 'primary.contrastText',
	    		p: 6,
	    	}}
    		container
    		direction="row"
    		alignItems="top"
    		justifyContent="space-between"
    		spacing={3}
    	>

    		<Grid item xs={12} sm={6}>	
    			<Typography sx={{ typography: { xs: 'h5', sm: 'h4', l: 'h3' }, color: 'white', marginLeft: '16px' }} >
    				Nuestra información es:
    			</Typography>
			    <List sx={{ bgcolor: 'main.contrastText' }}>
			    	{
			    		dataHighlights.map((highlight) => {
			    			return(
						      <ListItem alignItems="flex-start">
						        <ListItemText
						          primary={
						            <React.Fragment>
						              <Typography
						                sx={{ display: 'inline' }}
						                component="span"
						                variant="h5"
						                color="#FAFACB"
						              >
						                {highlight.title}
						              </Typography>
						            </React.Fragment>
						          }
						          secondary={
						            <React.Fragment>
						              <Typography
						                sx={{ display: 'inline' }}
						                component="span"
						                variant="body2"
						                color="white"
						              >
						                {highlight.description}
						              </Typography>
						            </React.Fragment>
						          }
						        />
						      </ListItem>
						     )
			    		})
			    	}
			    </List>
    		</Grid>

    		<Grid item xs={12} sm={6}>
    			<Typography sx={{ typography: { xs: 'h5', sm: 'h4', l: 'h3' }, color: 'white', marginLeft: '16px'}} >
    				Nuestro servicio permite:
    			</Typography>

			    <List sx={{ bgcolor: 'main.contrastText' }}>
	    			{
	    				features.map((feature) => {
	    					return (
						      <ListItem>
						      	<ListItemIcon>
				              <CircleIcon color='primary' />
				            </ListItemIcon>
						        <ListItemText
						          primary={
						            <React.Fragment>
						              <Typography
						                sx={{ display: 'inline' }}
						                component="span"
						                variant="body"
						                color="white"
						              >
						                {feature}
						              </Typography>
						            </React.Fragment>
						          }
						        />
						      </ListItem>
	    					);
	    				})
	    			}
			    </List>
    		</Grid>
    	</Grid>

    	<Grid
	    	sx={{
	    		bgcolor: 'secondary.main',
	    		p: 6,
	    	}}
    		container
    		direction="column"
    		alignItems="flex-start"
    		justifyContent="center"
    		spacing={1}
    	>
    		<Grid item xs={12}>
    			<Typography sx={{ typography: { xs: 'body2', sm: 'body' } }} >
    				Somos un servicio online que brinda información detallada sobre los centros de salud y médicos que ofrecen exámenes y consultas en Venezuela.
    			</Typography>
    		</Grid>

    		<Grid item xs={12}>
    			<Typography sx={{ typography: { xs: 'body2', sm: 'body' } }} >
						Tu Examen Médico surge con el objetivo de ayudar a los venezolanos a encontrar de manera centralizada, la información que necesitan para escoger el lugar más conveniente para hacer sus exámenes y programar sus citas médicas.
					</Typography>
				</Grid>

    		<Grid item sx={{marginTop: '32px', width: '50%', height: '50%'}}>
    			<Box>
						<img src="/temblue2.png" width='50%' height='50%'/>
					</Box>
    		</Grid>

    	</Grid>

      <Grid item>
        {/*<Zoom in={trigger}>*/}
          <Box
            // onClick={handleBackToTop}
            role="presentation"
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
          >
          	<Fab 
          		aria-label="scroll back to top" 
          		color='primary' 
          		// variant='extended'
          	>
              <WhatsAppIcon 
              	// sx={{ mr: 1 }} 
              	onClick={() => window.open(`https://wa.me/+1${ContactInfo.tuExamenMedico.whatsapp}`)}
              />
              {/*Contáctanos*/}
           	</Fab>
          </Box>
        {/*</Zoom>*/}
      </Grid>

    </Grid>
	)
})

export default LandingPage;