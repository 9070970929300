import React from 'react'
import { observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid';
import Typography from '@mui/material/Typography';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';

const WhatsApp = observer(({phones}) => {
  return(
  <Grid container justifyContent='flex-start' alignItems='center' spacing={1}>
    <Grid item>
      <IconButton >
        <WhatsAppIcon />
      </ IconButton>
    </Grid>
    <Grid item>
    { phones.map(function(phone) {
      return (
        <Grid item>
          <Link href={`https://wa.me/+58${phone}?text=https://www.tuexamenmedico.com%20🏥%0A%0A`} variant='body2' color='link'> {phone} </Link>
        </ Grid>
      )
    })}
  </ Grid>
</Grid>
  );
});

export default WhatsApp;