import React from 'react';
import { observer } from 'mobx-react'
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Grid from '@material-ui/core/Grid';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';



const useStyles = makeStyles((theme) => ({
  manrope: {
    fontFamily: 'manrope',
  },
  list: {
    maxWidth: '600px',
  },
  listItem: {
    // backgroundImage: 'linear-gradient(to right, red , yellow)',
  },
  listItemSale: {
    // backgroundImage: 'linear-gradient(to right bottom, #DCEEF8 , white)',
    // backgroundColor: '#DCEEF8'
  },
  saleCaption: {
    color: '#e85454',
    fontSize: 10,
    fontWeight: 'bold'
  },
  divider: {
    background: '#8bc8ea'
  }
}));

const TXMList = observer(({ services, exam }) => {

  let navigate = useNavigate();
  const classes = useStyles();

  return (
    <Grid container spacing={0} >
      <Grid item xs={12}>
        <Grid container>
        <List sx={{bgcolor: 'background.paper'}} className={classes.list}>
          { services.map(function(service) {
            return (
              <>
              <ListItem>
                <ListItemButton className={service?.plans?.sale ? classes.listItemSale : classes.listItem} onClick={() => navigate(`/servicio/${service._id}`)}>
                {
                  service?.plans?.sale ?
                  <Box mr={1}>
                  <Grid item xs={2}>
                    <Grid container direction='column' >
                      <Grid item xs={12}>
                        <ListItemIcon>
                          <LocalOfferIcon color='secondary' />
                        </ListItemIcon>
                      </Grid>
                      <Grid item>
                      <Typography variant='body2' className={classes.saleCaption}>
                        PROMO
                      </Typography>
                      </Grid>
                  </Grid>
                  </Grid>
                  </Box>
                  :
                  <></>
                }
                <Grid item>
                <ListItemText
                  primary={service.branch.name}
                  secondary={
                    <React.Fragment>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <Typography
                          variant="body2"
                          color="text.primary"
                          className={classes.manrope}
                        >
                          {`${service.address.municipality}, ${service.address.city}, ${service.address.state}`}
                        </Typography>
                      </Grid>
                      <Grid container direction="row" spacing={1}>
                        {
                          service.image ? <img src={`${service.image}`} style={{
                            height: 100,
                            width: '100%'
                          }}/> : <></>
                        }
                      </Grid>
                    </Grid>
                    </React.Fragment>
                  }
                />
                </Grid>
                </ListItemButton>
              </ListItem>
              <Divider variant="middle" component="li" className={classes.divider} />
              </>
            )
          })}
        </List>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default TXMList;