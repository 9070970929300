import { Routes as ReactSwitch, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ServiceDetailPage from './pages/ServiceDetailPage';
import SearchPage from './pages/SearchPage';
import LandingPage from './pages/LandingPage';
import DataUtilPage from './pages/DataUtilPage';

const Routes = () => {
  return (
    
    <ReactSwitch>
      <Route exact path='/' element={<LandingPage />}></Route>
      <Route exact path='/datautil' element={<DataUtilPage />}></Route>
      {/*<Route exact path='/landing' element={<LandingPage />}></Route>*/}
      <Route exact path='/buscar' element={<SearchPage />}></Route>
      <Route path='/servicio/:id' element={<ServiceDetailPage />}></Route>
    </ReactSwitch>
    
  )
}
export default Routes;