import React from 'react';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import ReactGA from "react-ga4";
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { Typography } from '@mui/material';
import { Link } from '@mui/material';
import { Box } from '@mui/material';
import { Grid } from '@mui/material';
import { Button } from '@mui/material';
import { Stack } from '@mui/material';
import { Fab } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import TwitterIcon from '@mui/icons-material/Twitter';
import InfoIcon from '@mui/icons-material/Info';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import CircleIcon from '@mui/icons-material/Circle';

import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import Stepper from '../components/Stepper';
import AppBar from '../components/AppBar';

import ContactInfo from '../utils/ContactInfo';

const features = [
	'Filtrar por ubicación, método de atención y formas de pago',
	'Encontrar exámenes y consultas en promoción ',
	'Compartir los datos con familiares y amigos',
	'Interactuar directamente con las instituciones y médicos',
	'Solicitar asistencia para búsquedas si se requiere',
	'Ahorrar tiempo al facilitar las búsquedas',
];

const steps = [
	'Haz click en busca y encuentra',
	'Escribe o selecciona el nombre del examen o consulta que necesitas',
	'Filtra tu búsqueda por ubicación, método de atención y formas de pago',
	'Selecciona el centro de atención o médico de tu preferencia',
	'Contacta directamente al centro de atención o al médico',
];

const dataHighlights = [
	{
		title: 'Confiable',
		description: 'Siempre actualizada',
	},
	{
		title: 'Centralizada',
		description: 'Todo en un mismo lugar',
	},
	{
		title: 'Numerosa',
		description: 'Miles de exámenes a consultar. Cientos de centros y médicos a donde acudir',
	},
	{
		title: 'Detallada',
		description: 'Toda la información que necesitas para escoger tu mejor opción',
	},
];


const DataUtilPage = observer(() => {
	let navigate = useNavigate();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: 'Data Util' });
  }, []);

	return (
    <Grid
      sx={{
        overflow: 'hidden',
      }}
      container
      direction="column"
      alignItems="center"
    >

    	{/* Section 1 */}
    	<Grid
	    	sx={{
	    		bgcolor: '#EDB220',
	    		p: 6,
	    	}}
    		container
    		direction="row"
    		alignItems="center"
    		justifyContent="center"
    		spacing={3}
    	>
    		<Grid 
    			item 
    			xs={12} 
    			sm={5} 
    			// sx={{marginTop: '32px', marginRight: '32px'}}
    		>
    			<Box>
						<img src="/dataUtilLogo.png" width='100%' height='100%'/>
					</Box>
    		</Grid>
    	</Grid>

			{/* Section 2 */}
    	<Grid
	    	sx={{
	    		bgcolor: 'primary.contrastText',
	    		p: 6,
	    	}}
    		container
    		direction="column"
    		alignItems="center"
    		justifyContent="center"
    		spacing={3}
    	>
    		{/*<Grid item xs={12}>*/}
    		<Box
    			sx={{
    				p: 4,
    			}}
    		>
    			<Typography color="white" sx={{ typography: { xs: 'body', sm: 'body' } }} >
    				Somos una Organización venezolana basada en el manejo de información online
    			</Typography>
    			<br/>
    			<Typography color="white" sx={{ typography: { xs: 'body', sm: 'body' } }} >
						Nacimos como respuesta a la necesidad que tiene la población venezolana de contar con datos confiables sobre diversas áreas que resultan relevantes en su día a día y la gran dificultad que existe actualmente para lograrlo, ya que no hay disponibilidad de fuentes centralizadas, confiables y de fácil acceso.
					</Typography>
					<br/>
					<Typography color="white" sx={{ typography: { xs: 'body', sm: 'body' } }} >
						Estamos conscientes de que nuestro éxito depende de la calidad y fiabilidad de los datos que manejamos. Entendemos que profundizar en el manejo de datos es la única vía para lograr la producción de información relevante, útil y accesible.
					</Typography>
					<br/>
					<Typography color="white" sx={{ typography: { xs: 'body', sm: 'body' } }} >
						Para ello recabamos los datos que nos suministran nuestros clientes, los analizamos, gestionamos, procesamos e interpretamos de forma automatizada, utilizando tecnología de punta para producir información  que satisfaga las necesidades, tanto de los clientes como de los usuarios de nuestros servicios.
					</Typography>
					<br/>
					<Typography color="white" sx={{ typography: { xs: 'body', sm: 'body' } }} >
						Finalmente ponemos la información obtenida a disposición de los venezolanos de forma gratuita, accesible y fácil de manejar
						!Le facilitamos la vida a la gente¡
    			</Typography>
    		</Box>
    		{/*</Grid>*/}

    	</Grid>

    	{/* Section 3 */}
    	<Grid
	    	sx={{
	    		bgcolor: '#80BAFF',
	    		p: 4,
	    	}}
    		container
    		direction="column"
    		alignItems="center"
    		justifyContent="center"
    		spacing={3}
    	>
    		<Grid item xs={12} sm={6}>	
    			<Typography sx={{ typography: { xs: 'h6', sm: 'h6' } }} >
						Te invitamos a utilizar nuestro primer servicio
					</Typography>			
    		</Grid>

				<Grid item xs={12} sm={6}>	
			    <Card sx={{ maxWidth: 345 }} raied='true'>
			      <CardActionArea onClick={() => navigate('/buscar')}>
			        <CardMedia
			          component="img"
			          height="140"
			          image="/temRedSlogan.png"
			        />
			        <CardContent>
			          <Typography gutterBottom variant="h5" component="div">
			            Tu Examen Médico
			          </Typography>
			          <Typography variant="body2">
			            Directorio de centros médicos en Venezuela
			          </Typography>
			        </CardContent>
			      </CardActionArea>
			      <CardActions>
            	<Link href={`https://wa.me/+1${ContactInfo.tuExamenMedico.whatsapp}`} variant='body2' color='link'>
	              <IconButton aria-label="WhatsApp" color="primary">
	                <WhatsAppIcon />
	              </IconButton>
            	</Link>

              <IconButton aria-label="Instagram" color="secondary" onClick={()=> window.open(ContactInfo.tuExamenMedico.instagram, "_blank")}>
                <InstagramIcon />
              </IconButton>

              <IconButton aria-label="Facebook" color="primary" onClick={()=> window.open(ContactInfo.tuExamenMedico.facebook, "_blank")}>
                <FacebookIcon />
              </IconButton>

              <IconButton aria-label="Twitter" color="primary" onClick={()=> window.open(ContactInfo.tuExamenMedico.twitter, "_blank")}>
                <TwitterIcon />
              </IconButton>

              <IconButton aria-label="Email" color="primary" onClick={()=> window.open(`mailto:${ContactInfo.tuExamenMedico.email}`, "_blank")}>
                <EmailIcon />
              </IconButton>
			      </CardActions>
			      <CardActions>
			        <Button size="small" color="secondary" onClick={() => navigate('/buscar')}>
			          Haz click aqui para empezar
			        </Button>
			      </CardActions>
			    </Card>
			   </Grid>
    	</Grid>

    	{/* Section 4 */}
    	<Grid
	    	sx={{
	    		bgcolor: 'primary.contrastText',
	    		p: 6,
	    	}}
    		container
    		direction="column"
    		alignItems="flex-start"
    		justifyContent="center"
    		spacing={1}
    	>
    		<Grid item xs={12}>
    			<Typography color='white' sx={{ typography: { xs: 'h6', sm: 'h6' } }} >
						Grupo Data Util, C.A.
    			</Typography>

					<Typography color='white' sx={{ typography: { xs: 'body2', sm: 'body' } }} >
						RIF: J-50115164-4
					</Typography>

					<Typography color='white' sx={{ typography: { xs: 'body2', sm: 'body' } }} >
						Direccion: Av. Francisco Solano López con Calle La Iglesia
						Edif. Centro Solano Plaza I, Ofic. 4-C, Sabana Grande
						Caracas-Venezuela
					</Typography>

					<Typography color='white' sx={{ typography: { xs: 'body2', sm: 'body' } }} >
						Teléfonos: 0212-7615107 / 0212-7613030
					</Typography>

					<Typography color='white' sx={{ typography: { xs: 'body2', sm: 'body' } }} >
						WhatsApp: +1(515)461-7484
					</Typography>

					<Typography color='white' sx={{ typography: { xs: 'body2', sm: 'body' } }} >
						E-mail: grupodatautil@gmail.com
					</Typography>

					<Typography color='white' sx={{ typography: { xs: 'body2', sm: 'body' } }} >
						IG: @data.util
					</Typography>

					<Typography color='white' sx={{ typography: { xs: 'body2', sm: 'body' } }} >
						FB: data.util
					</Typography>

					<Typography color='white' sx={{ typography: { xs: 'body2', sm: 'body' } }} >
						TW: @gdatautil
					</Typography>
    		</Grid>
    	</Grid>

    {/* utilities */}
      <Grid item>
        {/*<Zoom in={trigger}>*/}
          <Box
            // onClick={handleBackToTop}
            role="presentation"
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
          >
          	<Fab 
          		aria-label="scroll back to top" 
          		color='primary' 
          		// variant='extended'
          	>
              <WhatsAppIcon 
              	// sx={{ mr: 1 }} 
              	onClick={() => window.open(`https://wa.me/+1${ContactInfo.tuExamenMedico.whatsapp}`)}
              />
              {/*Contáctanos*/}
           	</Fab>
          </Box>
        {/*</Zoom>*/}
      </Grid>

    </Grid>
	)
})

export default DataUtilPage;