import React from 'react'
import Select from 'react-select'
import { observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';

import SendIcon from '@mui/icons-material/Send';
import DevicesIcon from '@mui/icons-material/Devices';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import GroupIcon from '@mui/icons-material/Group';

import HomeIcon from '@mui/icons-material/Home';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import FastForwardIcon from '@mui/icons-material/FastForward';
import IconButton from '@mui/material/IconButton';

import Divider from '@mui/material/Divider';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


const useStyles = makeStyles((theme) => ({
  manrope: {
		fontFamily: 'Manrope',
  },
}));

const resultTypesMap = {
	'Envío directo al doctor': {
		icon: (<SendIcon color='primary'/>),
	},
  'Online con clave': {
		icon: (<DevicesIcon color='primary'/>),
  },
  'Por correo': {
		icon: (<EmailIcon color='primary'/>),
  },
  'Por whatsapp': {
		icon: (<WhatsAppIcon color='primary'/>),
  },
  'Presencial': {
		icon: (<GroupIcon color='primary'/>),
  },
}


const ResultTypes = observer(({resultTypes}) => {

	const classes = useStyles();

  return(
      <Grid container>
        <Grid item>
          <Box m={1} pt={1}>
            <Typography variant="h6">
              Métodos de entrega de resultados
            </Typography>
          </Box>
        </Grid>
          <Grid item xs={12}>
              <List dense={true}>
  				      { resultTypes.map(function(resultType) {
  				        return (
  		                <ListItem>
  		                  <ListItemIcon>
  		                    {resultTypesMap[resultType].icon}
  		                  </ListItemIcon>
  		                  <ListItemText
  		                    primary={resultType}
  		                    primaryTypographyProps={{variant: 'body1'}}
  		                    className={classes.manrope}
  		                  />
  		                </ListItem>
  				        )
  				      	})}
              </List>
          </Grid>
        </Grid>
  );
});

export default ResultTypes;