import React from 'react';
import { observer } from 'mobx-react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@material-ui/core/Grid';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

const useStyles = makeStyles((theme) => ({
  manrope: {
    fontFamily: 'manrope',
  },
  list: {
    maxWidth: '600px',
  },
  listItem: {
    // backgroundColor: 'green'
  },
  box: {
    borderWidth: 1,
    borderColor: 'blue'
  },
  listItemSale: {
    // backgroundImage: 'linear-gradient(to right bottom, #DCEEF8 , white)',
    // backgroundColor: '#DCEEF8'
  },
  saleCaption: {
    color: '#e85454',
    fontSize: 10,
    fontWeight: 'bold'
  }
}));

const TopResultsList = observer(({ services, exam }) => {

  // Randomly selects `n` elements from `arr`
  const selectRandom = (arr, n) => {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  };


  // shuffle array if larger than 3 items
  if (services.length > 3) {
    services = selectRandom(services, 3);
  }

  let navigate = useNavigate();
  const classes = useStyles();

  return (
    <Grid container spacing={0} >
      <Grid item xs={12}>
        <List dense={true} sx={{ bgcolor: 'background.paper'}} className={classes.list}>
          <Box m={2} pt={2} pb={2} >
          { services.map(function(service) {
            return (
              <>
              <ListItem alignItems="flex-start" className={classes.listItem}>
                <ListItemButton className={service?.plans?.sale ? classes.listItemSale : classes.listItem} onClick={() => navigate(`/servicio/${service._id}`)}>
                  {
                    service?.plans?.sale ?
                    <Box mr={1}>
                    <Grid item xs={2}>
                      <Grid container direction='column' >
                        <Grid item xs={12}>
                          <ListItemIcon>
                            <LocalOfferIcon color='secondary' />
                          </ListItemIcon>
                        </Grid>
                        <Grid item>
                        <Typography variant='body2' className={classes.saleCaption}>
                          PROMO
                        </Typography>
                        </Grid>
                    </Grid>
                    </Grid>
                    </Box>
                    :
                    <></>
                  }
                  <ListItemText
                    primary={service.branch.name}
                    secondary={
                      <React.Fragment>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <Typography
                            variant="body2"
                            color="text.primary"
                            className={classes.manrope}
                          >
                            {`${service.address.municipality}, ${service.address.city}, ${service.address.state}`}
                          </Typography>
                        </Grid>
                        <Grid container direction="row" spacing={1}>
                          {
                            service.image ? <img src={`${service.image}`} style={{
                              height: 100,
                              width: '100%'
                            }}/> : <></>
                          }
                        </Grid>
                      </Grid>
                      </React.Fragment>
                    }
                  />
                </ListItemButton>
              </ListItem>

              </>
            )
          })}
          </Box>
        </List>
      </Grid>
    </Grid>
  );
});

export default TopResultsList;