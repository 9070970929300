import * as React from 'react';
import { observer } from 'mobx-react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useParams, useSearchParams, Link } from 'react-router-dom';
import SearchField from '../components/SearchField';
import { useStore as useServiceStore } from "../stores/serviceStore";
import Options from '../utils/Options';


const TxmAppBar = observer((navigateOnClick) => {
  const serviceStore = useServiceStore();
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const handleSetExam = async (exam) => {
    // setSearchParams({ q: exam }, {})
    serviceStore.clearFilters();
    serviceStore.setExams(exam);
    console.log(navigateOnClick)
    navigate({
      pathname: '/buscar',
      search: `?q=${exam}`,
    });
  }

  return (
    <Box sx={{ flexGrow: 1}}>
      <AppBar position="fixed" sx={{bgcolor: 'transparent', backdropFilter:'blur(10px) brightness(70%)'}}>
        <Toolbar>
        <Box pr={1}>
          <Link to="/">
            <img src="/temLettersOnly.png" width='70px' href='/'/>
          </Link>
        </Box>
          <Grid 
            container 
            direction='row'
            alignItems='center'
            justifyContent='center'
            spacing={5}
          >
            <Grid item xs={12} sx={{maxWidth: '600px'}}>
                <SearchField
                  name={'exams'} 
                  options={serviceStore.options.examNames || []}
                  defaultValue={serviceStore.filters['exams.name'] || 'Escribe tu examen o consulta'}
                  action={(exams) => {handleSetExam(exams)}}
                />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
})

export default TxmAppBar;