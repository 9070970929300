import React from 'react';
import { makeAutoObservable, runInAction } from 'mobx';
import ReactGA from "react-ga4";

let apiUrl = 'https://api.tuexamenmedico.com/services';

//TODO move this to a util function
if(window.location.href.startsWith('https://admin.tuexamenmedico.com/')) {
	// apiUrl = 'https://api.tuexamenmedico.com/servicees';
} else {
	// apiUrl = 'http://localhost:3002/servicees';
}

export default class serviceStore {
	id = '';
	saved = true;
	filtersLoading = false;
	loading = false;
	success = false;
	successMessage = '';

	service = {
		name: '',
		plans: {

		},
		exams: [],
		branch: {
			_id: '',
			name: '',
		},
		address: {
			city: '',
			municipality: '',
			neighbourhood: '',
			address: '',
		},
		contact: {
			phones: [],
			cellPhones: [],
			email: '',
			whatsapp: [],
		},
    appointments: [], // metodo de atencion
    businessSchedule: {
			monday: ['',''],
			tuesday: ['',''],
			wednesday: ['',''],
			thursday: ['',''],
			friday: ['',''],
			saturday: ['',''],
			sunday: ['',''],
			whatsapp: ['', ''],
			instagram: ['', ''],
    },
    specialServices: [],
    budgetRequest: {
      email: '',
      department: '',
      phones: [],
      contact: '',
    },
    resultsSchedule: {
			monday: ['',''],
			tuesday: ['',''],
			wednesday: ['',''],
			thursday: ['',''],
			friday: ['',''],
			saturday: ['',''],
			sunday: ['',''],
			instagram: ['',''],
    },
    paymentTypes: [],
    resultTypes: [],
    notes: {
      appointments: '',
      businessSchedule: '',
      specialServices: '',
      resultsSchedule: '',
      admin: '',
    },
    status: null,
    image: '',
	};

	options = {
		examNames: [],
	}

	// data about the search performed by service
	serviceSearch = {
		searchTerm: '',
		exam: '',
		results: [],
		topResults: [],
		count: 0,
	};

	filterOptions = {
		city: [],
		state: [],
		municipality: [],
		neighbourhood: [],
		specialServices: [],
		paymentTypes: [],
		appointments: [],
		resultTypes: [],
		sales: [],
	}

	filters = {
		address: {

		},
		// exams: null,
		// appointments: null,
		// specialServices: null,
		// resultTypes: null,
	}
	
	constructor() {
		makeAutoObservable(this);
	}

	async clear() {
		runInAction(() => {
			this.service = {
				name: '',
				exams: [],
				branch: {
					_id: '',
					name: '',
				},
				address: {
					city: '',
					municipality: '',
					neighbourhood: '',
					address: '',
				},
				contact: {
					phones: [''],
					cellPhones: [''],
					email: '',
					whatsapp: [''],
				},
		    appointments: [], // metodo de atencion
		    businessSchedule: {
					monday: ['',''],
					tuesday: ['',''],
					wednesday: ['',''],
					thursday: ['',''],
					friday: ['',''],
					saturday: ['',''],
					sunday: ['',''],
					whatsapp: ['', ''],
					instagram: ['', ''],
		    },
		    specialServices: [],
		    budgetRequest: {
		      email: '',
		      department: '',
		      phones: [],
		      contact: '',
		    },
		    resultsSchedule: {
					monday: ['',''],
					tuesday: ['',''],
					wednesday: ['',''],
					thursday: ['',''],
					friday: ['',''],
					saturday: ['',''],
					sunday: ['',''],
					instagram: ['',''],
		    },
		    paymentTypes: [],
		    resultTypes: [],
		    notes: {
		      appointments: '',
		      businessSchedule: '',
		      specialServices: '',
		      resultsSchedule: '',
		      admin: '',
		    },
		    status: null,
		    image: '',
			};
			this.loading = false;
			this.saved = true;
			this.id = ''; // this needs to be deprecated
		});
	}

	/* 
		Helper functions
	*/

// shuffles an array using the Fisher–Yates shuffle
	async shuffle(array) {
	  let now = Date.now();
	  let currentIndex = array.length,  randomIndex;

	  while (currentIndex != 0) {
	    randomIndex = Math.floor(Math.random() * currentIndex);
	    currentIndex--;

	    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
	  }
	  
	  console.log(`Shuffle time: ${Date.now() - now}ms`)
	  return array;
	}

	/* 
		API related functions
	*/

	async getAll() {
		this.loading = true;
		const response = await fetch(apiUrl  + '/');
		const serviceDocs = await response.json();

		runInAction(() => {
			this.serviceSearch.results = serviceDocs;
			this.serviceSearch.count = serviceDocs.length;
		});

		this.loading = false;
	}

	async find() {
		this.loading = true;
		this.filtersLoading = true;

		let url = `${apiUrl}/find?`;

		const query = {}
		if(this.filters['exams.name'])
			query['exams.name'] = this.filters['exams.name'];

		if(this.filters.address.city) 
			query['address.city'] = this.filters.address.city;
		
		if(this.filters.address.state) 
			query['address.state'] = this.filters.address.state;
		
		if(this.filters.address.municipality) 
			query['address.municipality'] = this.filters.address.municipality;
		
		if(this.filters.address.neighbourhood) 
			query['address.neighbourhood'] = this.filters.address.neighbourhood;

		if(this.filters.appointments) 
			query['appointments'] = this.filters.appointments;

		if(this.filters.specialServices) 
			query['specialServices'] = this.filters.specialServices;

		if(this.filters.resultTypes) 
			query['resultTypes'] = this.filters.resultTypes;

		if(this.filters.paymentTypes)
			query['paymentTypes'] = this.filters.paymentTypes;

		if(this.filters.sale)
			query['plans.sale'] = true;
		
		const docResponse = await fetch(url + new URLSearchParams(query));
		const serviceDocs = await docResponse.json();

		runInAction(async () => {
			this.serviceSearch.results = await this.shuffle(serviceDocs);
			this.serviceSearch.count = serviceDocs.length;

			let topResults = [];
			for (const service of this.serviceSearch.results) {
				// extract top results
				if(service.plans?.topResult) {
					topResults.push(service);
				}
			}
			this.serviceSearch.topResults = await this.shuffle(topResults);
		});

		this.loading = false;
		this.findDistinct();
	}

	async findDistinct() {
		let url = `${apiUrl}/findDistinct?`;

		const query = {}
		if(this.filters['exams.name'])
			query['exams.name'] = this.filters['exams.name'];

		if(this.filters.address.city) 
			query['address.city'] = this.filters.address.city;
		
		if(this.filters.address.state) 
			query['address.state'] = this.filters.address.state;
		
		if(this.filters.address.municipality) 
			query['address.municipality'] = this.filters.address.municipality;
		
		if(this.filters.address.neighbourhood) 
			query['address.neighbourhood'] = this.filters.address.neighbourhood;

		if(this.filters.appointments) 
			query['appointments'] = this.filters.appointments;

		if(this.filters.specialServices) 
			query['specialServices'] = this.filters.specialServices;

		if(this.filters.resultTypes) 
			query['resultTypes'] = this.filters.resultTypes;

		if(this.filters.paymentTypes)
			query['paymentTypes'] = this.filters.paymentTypes;

		if(this.filters.sale)
			query['plans.sale'] = true;
		
		const response = await fetch(url + new URLSearchParams(query));
		const distinctValues = await response.json();
		const filterOptions = distinctValues;

		runInAction(() => {
			this.filterOptions = filterOptions;
		});

		this.filtersLoading = false;
	}

  async getOne(serviceId) {
  	this.loading = true;
		const response = await fetch(`${apiUrl}/${serviceId}`);
		const serviceDoc = await response.json();

		runInAction(() => {
			this.id = serviceDoc._id;
			this.service = serviceDoc;
		});

		this.loading = false;
		ReactGA.event('select_item', { items: [{ item_id: serviceDoc._id, item_name: serviceDoc.branch.name }]});
  }

  async getDistinctExams() {
  	let url = `${apiUrl}/distinct?`;
  	this.loading = true;
		const response = await fetch(url + new URLSearchParams({field: 'exams.name'}));
		const responseObj = await response.json();

		runInAction(() => {
			this.options.examNames = responseObj.distinctValues;
		});

		this.loading = false;
  }

	clearFilters() {
		this.filters.address.city = '';
		this.filters.address.state = '';
		this.filters.address.municipality = '';
		this.filters.address.neighbourhood = '';
		this.filters.appointments = '';
		this.filters.resultTypes = '';
		this.filters.specialServices = '';
		this.filters.paymentTypes = '';
		this.filters.sale = false;
	}


	/* 
		Setters
	*/
	setState(state) {
		this.filters.address.state = state;
		this.find();
	}

	setCity(city) {
		this.filters.address.city = city;
		this.find();
	}

	setMunicipality(municipality) {
		this.filters.address.municipality = municipality;
		this.find();
	}

	setNeighbourhood(neighbourhood) {
		this.filters.address.neighbourhood = neighbourhood;
		this.find();
	}

	setAppointments(appointments) {
		this.filters.appointments = appointments;
		this.find();
	}

	setSearchTerm(searchTerm) {
		this.serviceSearch.searchTerm = searchTerm;
	}

	setResultsSchedule(schedule) {
		this.service.resultsSchedule = schedule;
		this.find();
	}

	setResultTypes(resultTypes) {
		this.filters.resultTypes = resultTypes;
		this.find();
	}

	setSpecialServices(specialServices) {
		this.filters.specialServices = specialServices;
		this.find();
	}

	setPaymentTypes(paymentTypes) {
		this.filters.paymentTypes = paymentTypes;
		this.find();
	}

	setExams(exams) {
		this.filters['exams.name'] = exams;
		this.find();
		// this.findDistinct();
	}

	setSuccess(success, message) {
		this.success = success;
		this.successMessage = message;
	}

	setSale(sale) {
		this.filters.sale = sale;
		this.find();
	}
}


/* Store helpers */
const StoreContext = React.createContext();
 
export const ServiceStoreProvider = ({ children, store }) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
 
/* This hook allows us to use the service store in any functional component */
export const useStore = () => React.useContext(StoreContext);