import React from 'react'
import Select from 'react-select'
import { observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';


const useStyles = makeStyles((theme) => ({
  label: {
    // minWidth: 100,
    // maxWidth: 150,
    // width: 120,
  },
}));

const TXMLabel = observer(({label, value}) => {

	const classes = useStyles();

  return(
  	<Box sx={{ flexGrow: 1 }}>
  	<Grid 
      container 
      direction="row"
      alignItems="center"
      // justifyContent="space-around"
      spacing={0}
  	>
			<Grid item xs={6}>
	      <Typography variant="h6" gutterBottom>
	      	{label}
	      </Typography>
	  	</Grid>
	  	<Grid item xs={6}>
	      <Typography variant="body2" gutterBottom>
					{value}
	      </Typography>
	  	</Grid>
  	</Grid>
  	</Box>
  );
});

export default TXMLabel;