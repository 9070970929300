import React from 'react'
import { observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid';
import Typography from '@mui/material/Typography';
import PhoneIcon from '@mui/icons-material/Phone';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';

const Schedule = observer(({title, schedule}) => {
  return(
  <Grid container justifyContent='flex-start' alignItems='center' spacing={1}>
    <Grid item > 
      {/*title goes here*/}
    </Grid>
    <Grid item>
        <Grid item>
          <Typography variant='body2'>
            Lunes {schedule.monday[0]} - {schedule.monday[1]}
          </Typography>

          <Typography variant='body2'>
            Martes {schedule.tuesday[0]} - {schedule.tuesday[1]}
          </Typography>

          <Typography variant='body2'>
            Miercoles {schedule.wednesday[0]} - {schedule.wednesday[1]}
          </Typography>

          <Typography variant='body2'>
            Jueves {schedule.thursday[0]} - {schedule.thursday[1]}
          </Typography>

          <Typography variant='body2'>
            Viernes {schedule.friday[0]} - {schedule.friday[1]}
          </Typography>

          {
            schedule.saturday[0] ? 
              (
                <Typography variant='body2'>
                  Sábado {schedule.saturday[0]} - {schedule.saturday[1]}
                </Typography>
              ) : <div></div>
          }

          {
            schedule.sunday[0] ? 
              (
                <Typography variant='body2'>
                  Domingo {schedule.sunday[0]} - {schedule.sunday[1]}
                </Typography>
              ) : <div></div>
          }


        </ Grid>
  </ Grid>
</Grid>
  );
});

export default Schedule;