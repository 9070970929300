import React, { useEffect } from 'react';
import { observer } from 'mobx-react'
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Options from '../utils/Options';
import Select from '../components/Select';
import { useStore as useServiceStore } from "../stores/serviceStore";
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';

import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import TuneIcon from '@mui/icons-material/Tune';

import Button from '@mui/material/Button';

import FilterPage from './FilterPage'

import List from '../components/List';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Zoom from '@mui/material/Zoom';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import Stack from '@mui/material/Stack';
// import Item from '@mui/material/Item';
import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';

import Link from '@mui/material/Link';

import ReactGA from "react-ga4";


const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 100,
  },
  mainLogo: {
    // width: '100%',
    // height: '100%',
    maxWidth: '600px',
    maxHeight: '600px',
  },
  temBlue: {
    width: '100%',
    height: '100%',
    maxWidth: '500px',
    maxHeight: '500px',

  },
  cornerLogo: {
    width: '40vw',
    hright: '40vw',
  },
  section2: {
    height: '100vh',
    backgroundColor: '#e85454'
  },
  section3: {
    height: '100vh',
    backgroundColor: '#132c5e' //8bc8ea
  },
  textBox: {
    width: '60%',
  },
  footerContainer: {
    width: '100vw'
  },
  manrope: {
    fontFamily: 'Manrope',
    color: '#FAFACB',
    padding: '8px',
  },
  button: {
    color: '#FAFACB'
  },
  dataUtil: {
    width: '80vw',
    height: '80vw',
    maxWidth: '500px',
    maxHeight: '500px',
  }
}));

const HomePage = observer(() => {

	const classes = useStyles();
  const serviceStore = useServiceStore();
  const containerRef = React.useRef(null);
  let navigate = useNavigate();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 300,
  });

  const [show, setShow] = React.useState(true);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

const toggleDrawer = (open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    return;
  }
  setDrawerOpen(open);
};

  const handleBackToTop = (event) => {
    const anchor = document.querySelector(
      '#tu-examen-medico',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const handleScrollTuExamenMedico = (event) => {
    const anchor = document.querySelector(
      '#tu-examen-medico-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const handleScrollDataUtil = (event) => {
    const anchor = document.querySelector(
      '#data-util-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const handleShow = () => {
    setShow((prev) => !prev);
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: 'Tu Examen Medico' });
  }, []);

	return (
    <Slide direction="right" in={show} mountOnEnter>
    <Box sx={{ flexGrow: 1 }}>

        <Grid 
          container
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          id="tu-examen-medico-anchor"
          className={classes.section2}
          >
            <Grid container justifyContent='center'>


            <Grid container justifyContent='center'>
              <Grid item>
                <Box>
                  <img src='temBlue.png' className={classes.temBlue} />
                </Box>
              </Grid>
            </Grid>
              <Grid item>
                <Box>
                  <Typography align="center" variant='h6' className={classes.manrope}> 
                    Directorio gratuito de exámenes médicos en Venezuela
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid container justifyContent='center'>
              <Grid item>
                <Box>
                  <Typography align="center"  variant='h6' className={classes.manrope}> 
                    ¿Necesitas un exámen?
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid container justifyContent='center'>
              <Grid item>
                <Button
                  variant="contained"
                  color='primary'
                  onClick={() => navigate("/buscar")}
                >
                  Busca y Encuentra
                </Button>
              </Grid>
            </Grid>

            <Grid container justifyContent='center'>
              <Grid item onClick={() => handleScrollDataUtil()}>
                <Box m={2}>
                  <Typography align="center" variant='body2' className={classes.manrope}> 
                    Página en construcción.
                  </Typography>
                  <Typography align="center" variant='body2' className={classes.manrope}> 
                    Un servicio de <Link variant='body2'> Data Util, C.A. </Link>
                  </Typography>
                </Box>
              </Grid>
            </Grid>

              <Grid item>
                <Zoom in={trigger}>
                  <Box
                    onClick={handleBackToTop}
                    role="presentation"
                    sx={{ position: 'fixed', bottom: 16, right: 16 }}
                  >
                   <Fab size="small" aria-label="scroll back to top" color='primary'>
                      <KeyboardArrowUpIcon onClick={() => handleScrollTuExamenMedico()}/>
                    </Fab>
                  </Box>
                </Zoom>
              </Grid>
          {/*</Box>*/}
        </Grid>

      <Grid 
        container
        direction="column"
        alignItems="center"
        justifyContent="space-evenly"
        id="data-util-anchor"
        className={classes.section3}
        >

        <Grid container justifyContent='center'>
          <Grid item>
              <img src='datautil.png' className={classes.dataUtil} />
          </Grid>
        </Grid>

        <Grid container justifyContent='center'>
          <Grid item>
            <Box m={2}>
              <Typography variant='body2' className={classes.manrope}> 
                Data Útil y Tu Exámen Médico (TEM) operan bajo el criterio de mejora contínua.
              </Typography>
              <Typography variant='body2' className={classes.manrope}> 
                Si no consigues el exámen médico que necesitas, por favor comunícate con nosotros para apoyarte y mejorar el servicio que prestamos.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid item>
          <Stack direction="row" spacing={2}>
{/*            <IconButton aria-label="Email" color="primary" onClick={()=> console.log('hello')}>
              <EmailIcon /> 
            </IconButton>
            <IconButton aria-label="WhatsApp" color="primary" onClick={()=> console.log('hello')}>
              <WhatsAppIcon />
            </IconButton>
            <IconButton aria-label="Instagram" color="primary" onClick={()=> console.log('hello')}>
              <InstagramIcon />
            </IconButton>
            <IconButton aria-label="Facebook" color="primary" onClick={()=> console.log('hello')}>
              <FacebookIcon />
            </IconButton>*/}
          </Stack>
        </Grid>
      </Grid>


    </Box>
      </Slide>
	);
});

export default HomePage;