import React from 'react'
import { observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const AdminNotes = observer(({note}) => {
  return(
      <Grid container>
        <Grid item>
          <Box m={1} >
            <Typography variant="h6">
              Observaciones
            </Typography>
          </Box>
        </Grid>

          <Grid item xs={12}>
            <Box m={2}>
              <Grid container spacing={1}>
                <Typography variant='body'>
                  {note}
                </Typography >
              </Grid>
            </Box>
          </Grid>
        </Grid>
  );
});

export default AdminNotes;